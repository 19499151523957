import React    from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App      from './App';
import '@fortawesome/fontawesome-free/css/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'typeface-roboto';

ReactDOM.render(
    <App />,
    document.getElementById('root'),
);
