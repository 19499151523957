import { Handles, Slider } from 'react-compound-slider';
import React               from 'react';

const Handle = ({ getHandleProps, handle: { id, value, percent } }) => {
    return (
        <div
            id='drag-handle'
            style={ {
                left: `${ percent }%`,

                backgroundColor: '#2C4870',
                color: '#333',
            } }
            { ...getHandleProps(id) }
        >
            <div id='slider-label'>
                { Math.round(value) }
            </div>
        </div>
    );
};

export default ({ maxIterations, setMaxIterations }) => {
    const sliderStyle = {
        position: 'relative',
        width: '100%',
        height: 20,
        marginTop: -24,
        marginBottom: 24,
    };

    const railStyle = {
        position: 'absolute',
        width: '100%',
        height: 8,
        marginTop: 35,
        borderRadius: 5,
        backgroundColor: '#eee',
    };

    return (
        <Slider
            rootStyle={ sliderStyle }
            domain={ [1, 2000] }
            values={ [maxIterations] }
            onUpdate={ ([iterations]) => {
                setMaxIterations(Math.round(iterations));
            } }
        >
            <div style={ railStyle }>
            </div>
            <Handles>
                { ({ handles, getHandleProps }) => (
                    <div className="slider-handles">
                        { handles.map(handle => (
                            <Handle
                                key={ handle.id }
                                handle={ handle }
                                getHandleProps={ getHandleProps }
                            />
                        )) }
                    </div>
                ) }
            </Handles>
        </Slider>
    );
};
