export default () => `
    #version 100
    precision highp float;
    
    uniform vec2 uIn_Min;
    uniform vec2 uIn_Max;
    
    attribute vec3 aIn_Position;
    
    varying vec2 vPosition;
    
    void main(void) {
        gl_Position = vec4(aIn_Position.x, aIn_Position.y, 0.0, 1.0);
        
        // Invert the coordinate system
        vec2 corrected = vec2(aIn_Position.x, -1.0 * aIn_Position.y);
        
        // Move to origin
        vec2 zeroBased = (corrected + 1.0) / 2.0;
        
        // Scale according to given range
        vPosition = zeroBased * (uIn_Max - uIn_Min) + uIn_Min;
    }
`;
