import React                  from 'react';
import RendererWithDrag       from './RenderWithDrag';
import FixedRatio             from './FixedRatio';
import useHistory             from './useHistory';
import useAnimatedRegion      from './useAnimatedRegion';
import IterationSlider        from './IterationSlider';
import { ASPECT_RATIO }       from './appProps';
import AlgorithmSelection     from './AlgorithmSelection';
import useAvailableAlgorithms from './useAvailableAlgorithms';
import ControlPanel           from './ControlPanel';
import Renderer               from './Renderer';
import Region                 from './Region';
import Vec2                   from './Vec2';
import AppDescription         from './AppDescription';

export default () => {
    const [maxIterations, setMaxIterations] = React.useState(40);
    const { region, setRegion, animateRegion } = useAnimatedRegion();
    const { pop, append, peek, clear } = useHistory();
    const [selectedAlgorithm, setSelectedAlgorithm] = React.useState('mandelbrot');
    const availableAlgorithms = useAvailableAlgorithms(maxIterations);

    // Clear the history and reset the initial region whenever the selected algorithm changes
    React.useEffect(() => {
        setRegion(availableAlgorithms[selectedAlgorithm].initialRegion);
        clear();
    }, [selectedAlgorithm]);

    const iterationSlider = (
        <IterationSlider
            maxIterations={ maxIterations }
            setMaxIterations={ setMaxIterations }
        />
    );

    const algorithmSelection = (
        <AlgorithmSelection
            availableAlgorithms={ availableAlgorithms }
            selectedAlgorithm={ selectedAlgorithm }
            setSelectedAlgorithm={ setSelectedAlgorithm }
        />
    );

    return (
        <>
            <div id='title' className='drop-shadow'>
                <h2>WebGL Accelerated Fractal Renderer</h2>
                <AppDescription />
            </div>
            <div id='renderer-content' className='drop-shadow'>
                <ControlPanel
                    iterationSlider={ iterationSlider }
                    algorithmSelection={ algorithmSelection }
                />
                <div id='renderer-size-watcher'>
                    <FixedRatio ratio={ ASPECT_RATIO }>
                        { ({ width, height }) => {
                            const onDragRelease = ({ top, left, bottom, right }) => {
                                if (
                                    (width > 0)
                                    && (height > 0)
                                ) {
                                    // Convert pixel coordinates within the region to model coordinates
                                    const scaleX = (positionPx) => region.min.x + (positionPx / width) * (region.max.x - region.min.x);
                                    const scaleY = (positionPx) => region.min.y + (positionPx / height) * (region.max.y - region.min.y);

                                    const newRegion = new Region(
                                        new Vec2(scaleX(left), scaleY(top)),
                                        new Vec2(scaleX(right), scaleY(bottom)),
                                    );

                                    animateRegion(newRegion);
                                    append(region);
                                }
                            };

                            const renderer = (
                                <Renderer
                                    height={ height }
                                    width={ width }
                                    maxIterations={ maxIterations }
                                    region={ region }
                                    fragmentSource={ availableAlgorithms[selectedAlgorithm].fragmentSource }
                                />
                            );

                            return (
                                <RendererWithDrag
                                    renderer={ renderer }
                                    width={ width }
                                    height={ height }
                                    onDragRelease={ onDragRelease }
                                    selectLastRegion={ () => animateRegion(pop()) }
                                    isUndoDisabled={ peek() === null }
                                />
                            );
                        } }
                    </FixedRatio>
                </div>
            </div>
        </>
    );
}
