const generateShaderProgram = (gl, shaders) => {
    const program = gl.createProgram();

    shaders.forEach((shader) => {
        gl.attachShader(program, shader);
    });

    gl.linkProgram(program);
    return program;
};

const checkShaderCompilation = (gl, shader) => {
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
        console.error(gl.getShaderInfoLog(shader));
    }
};

const generateShader = (gl, type, source) => {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    checkShaderCompilation(gl, shader);
    return shader;
};

const setupShaderProgram = (gl, vertexSource, fragmentSource) => {
    const vertexShader = generateShader(gl, gl.VERTEX_SHADER, vertexSource);
    const fragmentShader = generateShader(gl, gl.FRAGMENT_SHADER, fragmentSource);

    return generateShaderProgram(gl, [vertexShader, fragmentShader]);
};

export const getAspect = (gl) => {
    if (gl) {
        return gl.canvas.height / gl.canvas.width;
    }
    return 1;
};

export const setupCanvas = (canvas, vertexSource, fragmentSource) => {
    if (canvas) {
        const gl = canvas.getContext('webgl');

        if (gl === null) {
            alert(`It looks like you have WebGL disabled or your device doesn't support WebGL. Check your browser settings or view from another device`);
            return null;
        }
        const program = setupShaderProgram(gl, vertexSource, fragmentSource);
        gl.useProgram(program);
        return program;
    }
};

export const syncCanvasSize = (gl) => {
    gl.canvas.width = gl.canvas.clientWidth;
    gl.canvas.height = gl.canvas.clientHeight;
};