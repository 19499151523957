import React from 'react';

export default ({ selectedAlgorithm, setSelectedAlgorithm, availableAlgorithms }) => {
    return (
        <select
            className={ 'custom-select' }
            defaultValue={ selectedAlgorithm }
            onChange={ (e) => setSelectedAlgorithm(e.target.value) }
        >
            { Object.entries(availableAlgorithms).map(([key, { label }]) => {
                return (
                    <option
                        value={ key }
                        key={ key }
                    >
                        { label }
                    </option>
                );
            }) }
        </select>
    );
};
