import React from 'react';

export default ({ selectLastRegion, isDisabled }) => {
    return (
        <div id='undo-button'>
            <button
                type='button'
                disabled={ isDisabled }
                style={ {
                    zIndex: 10,
                } }
                onClick={ () => {
                    if (!isDisabled) {
                        selectLastRegion();
                    }
                } }
                className='btn btn-light'
            >
                <i className="fas fa-arrow-left" style={ { marginRight: 8 } } />
                Undo Zoom
            </button>
        </div>
    );
};
