import React            from 'react';
import DragRegion       from './DragRegion';
import UndoRegionButton from './UndoRegionButton';

export default ({ renderer, width, height, onDragRelease, selectLastRegion, isUndoDisabled }) => {
    const renderRef = React.useRef(null);

    return (
        <div style={ { position: 'relative' } }>
            <UndoRegionButton
                selectLastRegion={ selectLastRegion }
                isDisabled={ isUndoDisabled }
            />
            <div ref={ renderRef }>
                <DragRegion
                    parentRef={ renderRef }
                    width={ width }
                    height={ height }
                    onRelease={ onDragRelease }
                />
                { renderer }
            </div>
        </div>
    );
};
