export default class Vec2 {
    constructor(x, y) {
        this.x = x;
        this.y = y;
    }

    add(vec2) {
        return new Vec2(
            this.x + vec2.x,
            this.y + vec2.y,
        );
    }

    subtract(vec2) {
        return new Vec2(
            this.x - vec2.x,
            this.y - vec2.y,
        );
    }

    multiply(scalar) {
        return new Vec2(
            this.x * scalar,
            this.y * scalar,
        );
    }
};
