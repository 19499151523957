const RECTANGLE_VERTICES = [
    -1.0, -1.0, 0.0,
    -1.0, 1.0, 0.0,
    1.0, -1.0, 0.0,
    1.0, 1.0, 0.0,
    1.0, -1.0, 0.0,
    -1.0, 1.0, 0.0,
];

export default ({ region, gl, program }) => {
    const aInPosition = gl.getAttribLocation(program, 'aIn_Position');
    const uInCenter = gl.getUniformLocation(program, 'uIn_Center');
    const uInMax = gl.getUniformLocation(program, 'uIn_Max');
    const uInMin = gl.getUniformLocation(program, 'uIn_Min');

    gl.viewport(0, 0, gl.canvas.width, gl.canvas.height);
    gl.clearColor(0, 0, 0, 1);
    gl.clear(gl.COLOR_BUFFER_BIT);

    gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
    gl.enableVertexAttribArray(aInPosition);
    gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(RECTANGLE_VERTICES), gl.STATIC_DRAW);
    gl.vertexAttribPointer(aInPosition, 3, gl.FLOAT, false, 0, 0);
    gl.uniform2fv(uInCenter, new Float32Array([0, 0]));
    gl.uniform2fv(uInMin, new Float32Array([region.min.x, region.min.y]));
    gl.uniform2fv(uInMax, new Float32Array([region.max.x, region.max.y]));
    gl.drawArrays(gl.TRIANGLES, 0, RECTANGLE_VERTICES.length / 3);
};
