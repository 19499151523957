import Region           from './Region';
import Vec2             from './Vec2';
import { ASPECT_RATIO } from './appProps';

export const getFragment = (maxIterations) => `
    #version 100
    #define MAX_ITERATIONS ${ maxIterations }

    precision highp float;

    varying vec2 vPosition;
    
    float divergenceLimit = 4.0;
    
    void main(void) {
        vec2 sum = vec2(0.0);
        int iterations = 0;
        
        float zx = vPosition.x;
        float zy = vPosition.y;
        
        for (int i = 0; i < MAX_ITERATIONS; i++) {
            float xTemp = (zx * zx) - (zy * zy) + vPosition.x;
            zy = (-2.0 * zx * zy) + vPosition.y;
            zx = xTemp;
            
            // Modulus squared
            if ((zx * zx) + (zy * zy) >= divergenceLimit) {
                break;
            }
            
            iterations++;
        }
        
        float scaledIterations = (float(iterations) - log2(log2(dot(vec2(zx, zy), vec2(zx, zy)))) + 4.0) / float(MAX_ITERATIONS);
        
        gl_FragColor = vec4(vec3(1.0) - vec3(scaledIterations, scaledIterations, scaledIterations), 1.0);
    }
`;

const getInitialRegion = (aspectRatio) => {
    const initialMinX = -3;
    const initialMaxX = 2.5;
    const range = (initialMaxX - initialMinX);
    const yRange = range / aspectRatio;
    const initialMinY = -(yRange / 2);
    const initialMaxY = (yRange / 2);
    return new Region(
        new Vec2(initialMinX, initialMinY),
        new Vec2(initialMaxX, initialMaxY),
    );
};

export const initialRegion = getInitialRegion(ASPECT_RATIO);