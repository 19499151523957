import React from 'react';

export default () => (
    <div>
        <p>
            What you're looking at is an experiment using vanilla WebGL to render <a
            href='https://en.wikipedia.org/wiki/Fractal' target='_blank'
            rel="noopener noreferrer"
        >Fractals</a>. Each fractal has an algorithm which determines if a point in the complex plane "escapes" or is
            bounded, or
            somewhere in-between. The colours represent which points are part of a fractal's set. You can read more
            about them from the link above!
        </p>
        <p>
            This demo lets you alter the maximum number of iterations to check before determining if a point is part of
            the set. Generally CPUs are used to compute fractals, as they are able to <span
            className='font-italic'
        >easily</span> use arbitrary precision numbers to compute the fractal sets to an arbitrary precision. GPUs can
            also use 'arbitrary precision', but not easily, and are not really suited to this task anyway. They are on
            the other hand able to compute the sets at low precision very quickly using regular floating point
            precision. For this demo you'll reach a
            point where you can't zoom in any more and will end up with some ugly looking squares, but will be able to
            zoom around very quickly.
        </p>
    </div>
);
