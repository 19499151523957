import React            from 'react';
import useMouseDrag     from './useMouseDrag';
import { ASPECT_RATIO } from './appProps';

export default ({ parentRef, width, height, onRelease }) => {
    const [{ x: startX, y: startY }, setStartPosition] = React.useState({ x: 0, y: 0 });

    const computeBounds = ({ mouseX, mouseY }) => {
        let left = Math.min(mouseX, startX);
        let right = Math.max(mouseX, startX);
        let bottom = Math.max(mouseY, startY);
        let top = Math.min(mouseY, startY);

        if ((Math.max(mouseY, startY) - top) / (Math.max(mouseX, startX) - left) > ASPECT_RATIO) {
            const range = (Math.max(mouseY, startY) - top) * ASPECT_RATIO;
            if (mouseX > startX) {
                right = (startX + range);
            } else {
                left = startX - range;
            }
        } else {
            const range = (Math.max(mouseX, startX) - left) / ASPECT_RATIO;
            if (mouseY > startY) {
                bottom = startY + range;
            } else {
                top = startY - range;
            }
        }

        return {
            top,
            left,
            bottom,
            right,
        };
    };

    const onMouseRelease = ({ mouseX, mouseY }) => {
        const diffX = Math.abs(mouseX - startX);
        const diffY = Math.abs(mouseY - startY);
        const MIN_DIFF_REQUIRED_FOR_DRAG = 4;
        if (Math.max(diffX, diffY) > MIN_DIFF_REQUIRED_FOR_DRAG) {
            onRelease(computeBounds({ mouseX, mouseY }));
        }
    };

    const onMouseDown = ({ mouseX, mouseY }) => {
        setStartPosition({ x: mouseX, y: mouseY });
    };

    const { isDragging, mouseX, mouseY } = useMouseDrag(parentRef.current, onMouseDown, onMouseRelease);

    if (!isDragging) {
        return null;
    }

    const {
        top,
        left,
        bottom,
        right,
    } = computeBounds({ mouseX, mouseY });

    return (
        <div
            style={ {
                position: 'absolute',
                top: `${ top }px`,
                left: `${ left }px`,
                bottom: `${ height - bottom }px`,
                right: `${ width - right }px`,
                zIndex: 1000,
                border: `1px solid #000`,
                pointerEvents: 'none',
            } }
        />
    );
}