import ReactResizeDetector from 'react-resize-detector';
import React               from 'react';

export default ({ children, ratio = 1.6 }) => {
    return (
        <ReactResizeDetector handleWidth>
            { ({ width = 0 }) => {
                const height = width / ratio;

                return (
                    <div
                        style={ {
                            width,
                            height,
                        } }
                    >
                        { children({ width, height }) }
                    </div>
                );
            } }
        </ReactResizeDetector>
    );
};
