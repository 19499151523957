import React         from 'react';
import ExternalLinks from './ExternalLinks';

export default ({ iterationSlider, algorithmSelection }) => {
    return (
        <div id='control-panel'>
            <div id='control-panel-controls'>
                <div>
                    <i className="fas fa-info-circle" style={ { marginRight: 8 } } />
                    Try dragging a region on the fractal panel to zoom in
                </div>
                <div style={ { marginBottom: 48 } }>
                    <label>Maximum Iterations</label>
                    { iterationSlider }
                </div>
                <div>
                    <label>Fractal Algorithm</label>
                    { algorithmSelection }
                </div>
            </div>
            <ExternalLinks />
        </div>
    );
};
