import React from 'react';
import raf   from 'raf';

export default (DURATION, currentValue, setValue, interpolate) => {
    const [{
        isRunning,
        startTime,
        finalValue,
        elapsedTime,
        startValue,
    }, setState] = React.useState({
        isRunning: false,
        startTime: null,
        finalValue: null,
        elapsedTime: null,
        startValue: null,
    });

    React.useEffect(() => {
        function loopAnimation() {
            setValue((prevValue) => {
                const now = Date.now();
                if ((now - startTime) > DURATION) {
                    setState((prevState) => ({
                        ...prevState,
                        isRunning: false,
                    }));
                    return finalValue;
                } else {
                    setState((prevState) => ({
                        ...prevState,
                        elapsedTime: now,
                    }));

                    const sinceLast = now - elapsedTime;
                    const percentage = sinceLast / DURATION;
                    return interpolate(prevValue, percentage, startValue, finalValue);
                }
            });
        }

        if (isRunning) {
            const timerId = raf(loopAnimation);
            return () => raf.cancel(timerId);
        }
    }, [isRunning, startTime, finalValue, elapsedTime, DURATION, interpolate, setValue, startValue]);

    return (newValue) => {
        const now = Date.now();
        setState({
            isRunning: true,
            startTime: now,
            startValue: currentValue,
            finalValue: newValue,
            elapsedTime: now,
        });
    };
};
