export default class Region {
    constructor(min, max) {
        this.min = min;
        this.max = max;
    }

    add(region) {
        return new Region(
            this.min.add(region.min),
            this.max.add(region.max),
        );
    }

    subtract(region) {
        return new Region(
            this.min.subtract(region.min),
            this.max.subtract(region.max),
        );
    }

    multiply(scalar) {
        return new Region(
            this.min.multiply(scalar),
            this.max.multiply(scalar),
        );
    }
}
