import React                                      from 'react';
import getVertex                                  from './vertex';
import { getAspect, setupCanvas, syncCanvasSize } from './glProgram';
import draw                                       from './draw';

export default ({ width, height, maxIterations, region, fragmentSource }) => {
    const canvas = React.useRef(null);
    const [isGlSupported, setIsGlSupported] = React.useState(true);

    React.useEffect(() => {
        const gl = canvas.current.getContext('webgl');
        if (isGlSupported) {
            const program = setupCanvas(canvas.current, getVertex(), fragmentSource);
            if (program) {
                syncCanvasSize(gl);
                draw({ region, gl, aspect: getAspect(gl), program });
            } else {
                setIsGlSupported(false);
            }
        }
    }, [width, height, canvas.current !== null, maxIterations, region, fragmentSource, isGlSupported]);

    return (
        <canvas
            ref={ canvas }
            width={ width }
            height={ height }
        />
    );
};
