import React from 'react';

export default (element, onMouseDown, onMouseUp) => {
    const [[x, y], setMousePosition] = React.useState([0, 0]);
    const [isDragging, setIsDragging] = React.useState(false);

    React.useEffect(() => {
        const handleMove = (e) => {
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left;
            const y = e.clientY - rect.top;
            setMousePosition([x, y]);
        };

        const handleDown = () => {
            setIsDragging(true);
            onMouseDown({ mouseX: x, mouseY: y });
        };
        const handleUp = () => {
            if (isDragging) {
                onMouseUp({ mouseX: x, mouseY: y });
            }
            setIsDragging(false);
        };

        if (element) {
            element.addEventListener('mousemove', handleMove);
            element.addEventListener('mousedown', handleDown);
            element.addEventListener('mouseup', handleUp);
        }

        return () => {
            if (element) {
                element.removeEventListener('mousemove', handleMove);
                element.removeEventListener('mousedown', handleDown);
                element.removeEventListener('mouseup', handleUp);
            }
        };
    });

    return {
        isDragging,
        mouseX: x,
        mouseY: y,
    };
};
