import { getFragment as getMandelbrotFragment, initialRegion as initialMandelbrotRegion }   from './mandelbrot';
import { getFragment as getBurningShipFragment, initialRegion as initialBurningShipRegion } from './burningShip';
import { getFragment as getTricornFragment, initialRegion as initialTricornRegion }         from './tricorn';

export default (maxIterations) => {
    return {
        mandelbrot: {
            label: 'Mandelbrot',
            fragmentSource: getMandelbrotFragment(maxIterations),
            initialRegion: initialMandelbrotRegion,
        },
        burningShip: {
            label: 'Burning Ship',
            fragmentSource: getBurningShipFragment(maxIterations),
            initialRegion: initialBurningShipRegion,
        },
        tricorn: {
            label: 'Tricorn',
            fragmentSource: getTricornFragment(maxIterations),
            initialRegion: initialTricornRegion,
        },
    };
};
