import React from 'react';

export default () => (
    <div
        id='external-links'
    >
        <div><a href='https://d-brown.uk' target='_blank' rel="noopener noreferrer">Website</a></div>
        <div><a href='https://david-brown.dev' target='_blank' rel="noopener noreferrer">Blog</a></div>
    </div>
);
