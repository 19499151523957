import React from 'react';

export default () => {
    const [history, setHistory] = React.useState([]);

    const pop = () => {
        const lastElement = history[history.length - 1];
        const newHistory = history.splice(0, history.length - 1);
        setHistory([
            ...newHistory,
        ]);
        return lastElement;
    };

    const append = (element) => {
        setHistory((prevHistory) => ([
            ...prevHistory,
            element,
        ]));
    };

    const peek = () => {
        if (history.length === 0) {
            return null;
        }
        return history[history.length - 1];
    };

    const clear = () => {
        setHistory([]);
    };

    return { pop, append, peek, clear };
};
