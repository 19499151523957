import React        from 'react';
import Region       from './Region';
import Vec2         from './Vec2';
import useAnimation from './useAnimation';

export default () => {
    const [region, setRegion] = React.useState(new Region(
        new Vec2(0, 0),
        new Vec2(0, 0),
    ));

    const interpolateRegion = (prevRegion, percentage, startRegion, endRegion) => {
        const range = endRegion.subtract(startRegion);
        const thisFrame = range.multiply(percentage);
        return prevRegion.add(thisFrame);
    };

    const startAnimation = useAnimation(500, region, setRegion, interpolateRegion);

    return {
        region,
        setRegion,
        animateRegion: startAnimation,
    };
};
